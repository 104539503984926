/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";

const language = window.navigator.userLanguage || window.navigator.language;

const PricingContent = [
  {
    pcakName: "FULL PACKAGE (PLN)",
    bgColor: "#ffecec",
    price: "19.99 PLN",
    packDetails: "",
    features: [
      `E-Book (PDF, EPUB, Mobi): "Learn to Deliver Apps in SAP BTP, Cloud Foundry Runtime" - 173 pages, format 250 x 154 mm, English & Polish`,
      "Access to code repository of applications from the book",
      "Access to my private module zhana-client: hana-client wrapper on steroids (proof-of-concept)",
    ],
    animationDelay: "",
    activeItem: "active",
    url: "https://app.easycart.pl/checkout/11325590/learn-to-deliver-apps-in-sap-btp",
    pricePL: "49 PLN",
    priceId:
      process.env.NODE_ENV === "production"
        ? "price_1KFmaEJtJj2vYbwIbN6hrKB9"
        : "price_1KFm63JtJj2vYbwIEVr8MNYy",
    priceIdPL:
      process.env.NODE_ENV === "production"
        ? "price_1KFmaEJtJj2vYbwI4C4R4zMu"
        : "price_1KFlufJtJj2vYbwIXcpvMdfT",
  },
  {
    pcakName: "FULL PACKAGE (EUR)",
    bgColor: "#E3F8EF",
    price: "4.99 EUR",
    packDetails: "",
    features: [
      `E-Book (PDF, EPUB, Mobi): "Learn to Deliver Apps in SAP BTP, Cloud Foundry Runtime" - 173 pages, format 250 x 154 mm, English & Polish`,
      "Access to code repository of applications from the book",
      "Access to my private module zhana-client: hana-client wrapper on steroids (proof-of-concept)",
    ],
    animationDelay: "100",
    activeItem: "active",
    url:"https://app.easycart.pl/checkout/11325590/learn-to-deliver-apps-in-sap-eur-btp",
    priceId:
      process.env.NODE_ENV === "production"
        ? "price_1KFmbKJtJj2vYbwI2wmy4SQa"
        : "price_1KFlyiJtJj2vYbwIQf8VGL53",
    pricePL: "89 PLN",
    priceIdPL:
      process.env.NODE_ENV === "production"
        ? "price_1KFmbKJtJj2vYbwIXlCo0wLc"
        : "price_1KFlzmJtJj2vYbwI9kqWDJrc",
  },
];

const buy = async (url) => {
  // const stripeUrl =
  //   process.env.NODE_ENV === "production"
  //     ? "https://btp-ninja-api.herokuapp.com/api/create-checkout-session"
  //     : "/api/create-checkout-session";

  // const response = await axios.post(stripeUrl, { priceId });
  // const { session } = response.data;
  window.location.href = url;
};

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => {
        return (
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
            key={i}
          >
            <div className={`pr-table-wrapper ${val.activeItem}`}>
              <div className="pack-name" style={{ background: val.bgColor }}>
                {val.pcakName}
              </div>
              <div className="price">{val.price}</div>
              <div className="pack-details">{val.packDetails}</div>
              <ul className="pr-feature">
                {val.features.map((list, i) => (
                  <li key={i}>{list}</li>
                ))}
              </ul>
              <a
                href={val.url}
                // onClick={() => buy()}
                className="trial-button"
              >
                Buy now for {val.price}
              </a>
              
              {/* <div className="trial-text">No card required, cancel any time</div> */}
            </div>
            {/* /.pr-table-wrapper */}
          </div>
        );
      })}
    </div>
  );
};

export default PricingMonthly;
